import "../../App.css";
import "./header.css";

function HeaderSection() {
    return (
      <header>
      <h3>YEDIDTHIS.COM IS NOT DESIGNED FOR MOBILE</h3>
        {/* <div className="header_container"> */}
        {/* <h1 className="header_title">kanYe West</h1> */}
        {/* <h4 className="header_subtitle">" If you're a fan of <span className="text_glow cartoony">Ye</span>, you're a fan of <span className="text_glow cartoony">Yourself</span> "</h4> */}
        {/* <div className="scroll" /> */}
        {/* </div> */}
      </header>
    );
}

export default HeaderSection;